import React from "react"
import Layout from "../components/layout"

import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

import { Gallery, Item } from "react-photoswipe-gallery"

export default function galleryPage() {
  return (
    <Layout>
      {/* Gallery */}
      <div className="my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <Gallery>
          <Item
            original={"/1.webp"}
            thumbnail={"/1.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 lg:col-span-2 cursor-pointer transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/1.webp"}
                alt="inside of the restaurant"
              />
            )}
          </Item>
          <Item
            original={"/2.webp"}
            thumbnail={"/2.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 lg:col-span-2 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/2.webp"}
                alt="inside of the restaurant"
              />
            )}
          </Item>
          <Item
            original={"/3.webp"}
            thumbnail={"/3.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 lg:col-span-4 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/3.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
          <Item
            original={"/4.webp"}
            thumbnail={"/4.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 lg:col-span-2 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/4.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
          <Item
            original={"/5.webp"}
            thumbnail={"/5.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 sm:col-span-2 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/5.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
          <Item
            original={"/6.webp"}
            thumbnail={"/6.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/6.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
          <Item
            original={"/7.webp"}
            thumbnail={"/7.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/7.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
          <Item
            original={"/8.webp"}
            thumbnail={"/8.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 sm:col-span-2 lg:col-span-2 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/8.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
          <Item
            original={"/9.webp"}
            thumbnail={"/9.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 sm:col-span-1 lg:col-span-2 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/9.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
          <Item
            original={"/10.webp"}
            thumbnail={"/10.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 sm:col-span-1 lg:col-span-2 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/10.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
          <Item
            original={"/11.webp"}
            thumbnail={"/11.webp"}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <img
                className="col-span-1 sm:col-span-2 lg:col-span-4 cursor-pointer	 transition-transform	transform hover:scale-103 lg:hover:scale-102"
                ref={ref}
                onClick={open}
                src={"/11.webp"}
                alt="inside of the restaurant"
                loading="lazy"
              />
            )}
          </Item>
        </Gallery>
      </div>
    </Layout>
  )
}
